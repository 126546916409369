import { z } from 'zod';
import { zh } from './helpers/zodHelpers';

export const signatureMetadataSchema = z.object({
  signatureImage: zh.string().required(),
  signedByNameText: zh.string().required(),
  signedAtDateTimeUtc: z.date(),
});
export type SignatureMetadata = z.infer<typeof signatureMetadataSchema>;

export const signatureConfirmation = z
  .object({ signatureMetadata: signatureMetadataSchema })
  .or(z.object({ signatureBypassed: z.literal(true) }));
export type SignatureConfirmation = z.infer<typeof signatureConfirmation>;
